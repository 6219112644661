
.footer-page {
    width: 100%;
    background: #fff;
    .top-footer-wrap {
        margin-top: 31px;
        background: #bd1a2d;
        height: 207px;
        box-sizing: border-box;
        padding: 33px 0 0;
        .top-footer {
            display: flex;
            justify-content: space-between;
            .left-top-footer {
                width: 792px;
                box-sizing: border-box;
                padding: 20px 40px;

                p {
                    text-align: left;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #fff;
                    line-height: 23px;
                }
            }
            .right-top-footer {
                width: 398px;
                height: 159px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                padding: 13px 70px 0;
                div {
                    img {
                        width: 112px;
                        height: 113px;
                    }
                    p {
                        line-height: 21px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #fff;
                        text-align: center;
                    }
                }
            }
            .com-footer {
                background: #a91728;
            }
        }
    }
    .bot-footer-wrap {
        background: #0D1020;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        line-height: 60px;
    }
}
